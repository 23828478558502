import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Login } from '../../requests/login';
import './Login.css'; 

export default function LoginForm() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
  }

  function handlePasswordChange(e) {
    const { name, value } = e.target
    setPassword(value)
  }

  function handleButtonSubmit(e) {
    e.preventDefault() 
    Login.list(email, password).then (
      (data) => {
        console.lo(data)
      }
    )
  }

  return (
    <div className='inicio_formulario_login'>
      <h1 className='titulo_login'>Herramienta generadora de etiquetas</h1>
      <p className='p_login'>Inicia sesión o registrate para acceder a la herramienta generadora de etiquetas para Residuos peligrosos.</p>
      <img className="imagen_login" title='Iniciar sesión' alt="" src="/img/login128.png"/>
      <h3 className='sub_login'>Iniciar sesión</h3>
      <form onSubmit={handleButtonSubmit}>
        <label className="display_inputs_login" for> 
          <input
            className='inputs_login'
            type="text"
            name="email"
            placeholder='CORREO ELECTRÓNICO'
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label className="display_inputs_login" for> 
          <input
            className='inputs_login'
            type="password"
            name="password"
            placeholder='CONTRASEÑA'
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <p className='olvidar_contra_login'>¿Has olvidado la contraseña?</p>
        <label className='checkbox_login' for> 
          <input type="checkbox" className='input_checkbox_login' id="recuerdame" name="recuerdame" value="recuerdame"/> 
          <label className='label_checkbox_login' for="recuerdame">Recuérdame</label>
        </label>
        <button type="submit" className='btn_form_login'>Acceder</button>
      </form>
        <Link to="/registro" className='crear_cuenta_login'>
          ¿No tienes cuenta? Crea una aquí
        </Link>
      {/*
      <div>
        <h2>Values of the form</h2>
        <p>Email: {email}</p>
        <p>Password: {password}</p>
      </div>
      */}
    </div>
  )
}

