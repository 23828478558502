import React, { useState } from 'react';
import './Vista.css';
import Menu from '../Menu/Menu';
import ElementoLista from '../ElementoLista/ElementoLista';

export default function VistaForm() {

  const [imagen, setImagen] = useState('');
  const [c_ler, setC_Ler] = useState('');
  const [n_residuo, setN_Residuo] = useState('');

  function handleImagenChange(e) {
    const { name, value } = e.target
    setImagen(value)
  }

  function handleC_LerChange(e) {
    const { name, value } = e.target
    setC_Ler(value)
  }

  function handleN_ResiduoChange(e) {
    const { name, value } = e.target
    setN_Residuo(value)
  }

  function handleButtonSubmit(e) {
    e.preventDefault()
    alert(c_ler)
  }
  let array_li = [1, 2]
  return (
    <div className='wrapper'>
      <Menu index={2}/>
      <div className='div_principal_etiquetas'>
        <h2 className='titulo_etiquetas'>Mis etiquetas</h2>
        <ul>
          <li>
            <ElementoLista detalles="Conservantes de la madera organometálicos" 
            cod_log="030203*" image="/img/login128.png" residuo="MADERIN PLUS"/>
          </li>
          {
            array_li.map(item => {
              return (<li>
                elemento {item}
              </li>)
            })
          }
          <li>
            <ElementoLista detalles="Conservantes de la madera organometálicos" 
            cod_log="030203*" image="/img/login128.png" residuo="MADERIN PLUS"/>       
          </li>
        </ul>
           
      </div>
    </div>
  )
}

