import React, { useState } from 'react';
import './Generador.css';
import Menu from '../Menu/Menu';
import { jsPDF } from "jspdf";

export default function GeneradorForm() {

  const [empresa, setEmpresa] = useState(''); 
  const [nima, setNima] = useState(''); 
  const [email, setEmail] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [n_residuo, setN_Residuo] = useState('');
  const [codigo_ler, setCodigo_Ler] = useState('');
  const [fecha_envasado, setFecha_Envasado] = useState('');
  const [observaciones, setObservaciones] = useState('');


  function handleEmpresaChange(e) {
    const { name, value } = e.target
    setEmpresa(value)
  }

  function handleNimaChange(e) {
    const { name, value } = e.target
    setNima(value)
  }

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
  }

  function handleDireccionChange(e) {
    const { name, value } = e.target
    setDireccion(value)
  }

  function handleTelefonoChange(e) {
    const { name, value } = e.target
    setTelefono(value)
  }

  function handleN_Residuo(e) {
    const { name, value } = e.target
    setN_Residuo(value)
  }


  function handleCodigo_Ler(e) {
    const { name, value } = e.target
    setCodigo_Ler(value)
  }

  function handleFecha_Envasado(e) {
    const { name, value } = e.target
    setFecha_Envasado(value)
  }

  function handleObservaciones(e) {
    const { name, value } = e.target
    setObservaciones(value)
  }

  function quitarEtiqueta(numero) {
    var div_con_etiquetas = document.getElementsByClassName("div_con_etiquetas")[numero];
    alert(numero);
  }

  function handleButtonSubmit(e) {
    e.preventDefault()
    alert(empresa)
    const doc = new jsPDF();

    doc.text("Hello world!", 10, 10);
    doc.save("a4.pdf");
  }

  return (
    <div class="wrapper"> 
      <Menu index={0}/>
      <form className='form_generador' onSubmit={handleButtonSubmit}>
        <h2>Generador de etiquetas</h2>
        <p>1. Datos de la empresa y NIMA:</p>
        <label className='label_generador' for>
          <input
            className='inputs_generador margen_inputs_generador'
            type="text"
            name="empresa"
            placeholder='EMPRESA: ILUNE SL'
            value={empresa}
            onChange={handleEmpresaChange}
          /> 
          <input
            className='inputs_generador margen_generador'
            type="text"
            name="nima"
            placeholder='NIMA: 3784623'
            value={nima}
            onChange={handleNimaChange}
          />
          <input
            className='inputs_generador margen_generador'
            type="text"
            name="email"
            placeholder='EMAIL: daniel@ilune.com'
            value={email}
            onChange={handleEmailChange}
          />
          <input
            className='inputs_generador margen_generador'
            type="text"
            name="direccion" 
            placeholder='DIRECCIÓN: Plaza Sancho Abarca 2, BAJO. 31014 PAMPLONA'
            value={direccion}
            onChange={handleDireccionChange}
          />
          <input
            className='inputs_generador margen_generador'
            type="tel"
            name="telefono" 
            placeholder='TELÉFONO: 948 14 39 80'
            value={telefono}
            onChange={handleTelefonoChange}
          />
        </label>
        <p>2. Nombre del residuo:</p>
        <label className='label_generador' for>
          <input
            className='inputs_generador'
            type="text"
            name="n_residuo" 
            value={n_residuo}
            onChange={handleN_Residuo}
          /> 
        </label>
        <p>3. Selección código LER:</p>
        <label className='label_generador' for>
          <select className='opciones_generador' name="codigo_ler" value={codigo_ler} onChange={handleCodigo_Ler}>
            <option value=""></option>
            <option value="value2">Value 2</option>
            <option value="value3">Value 3</option>
          </select> 
        </label>
        <p>4. Fecha de envasado:</p>
        <label className='label_generador' for>
          <input
            className='inputs_generador'
            type="text"
            name="fecha_envasado" 
            value={fecha_envasado}
            onChange={handleFecha_Envasado}
          /> 
        </label>
        <p>5. Observaciones:</p>
        <label className='label_generador' for>
          <textarea className='inputs_generador' id="observaciones" name="observaciones" rows="5" cols="50" value={observaciones} onChange={handleObservaciones}> 
          </textarea> 
        </label> 
        <p className='parrafo_caracteristicas'>6. Características de peligrosidad HP y selección de Pictograma:<br/>¿Cómo saber qué pictogramas elegir (HP)?<div className='simbolo_interrogacion'>?</div></p>
        <div className='div_todas_etiquetas'>
          <div className='div_sin_etiquetas esconder'>
            <p className='simbolo_mas'>+</p>  
          </div>
          <div className='div_con_etiquetas mostrar'> 
            <img className="imagen_etiqueta" title='Etiqueta' alt="" src="/img/login128.png"/>
            <div className='simbolo_x'>x</div> 
          </div>
          <div className='div_sin_etiquetas mostrar'>
            <p className='simbolo_mas'>+</p>
          </div>
          <div className='div_con_etiquetas esconder'> 
            <img className="imagen_etiqueta" title='Etiqueta' alt="" src="/img/login128.png"/>
            <div className='simbolo_x'>x</div> 
          </div>
          <div className='div_sin_etiquetas mostrar'>
            <p className='simbolo_mas'>+</p>
          </div>
          <div className='div_con_etiquetas esconder'> 
            <img className="imagen_etiqueta" title='Etiqueta' alt="" src="/img/login128.png"/>
            <div className='simbolo_x'>x</div> 
          </div>
        </div>
        <p>7. Selección de tamaño y posición de la etiqueta o<br/> etiquetas para imprimir:</p>
        <div className='div_todos_tamanios'>
          <div className='div_a4'>
            <div className='div_tamanio_a4'>
              <div className='div_interno_a4'>
                <span className='span_tamanio_a4'><i>A4</i></span>
              </div>
            </div> 
          </div>
          <div className='div_a5'>
            <div className='div_tamanio_a5'>
              <div className='div_interno_a5'>
                <span className='span_tamanio_a5'><i>A5</i></span>
              </div> 
            </div>
          </div>
        </div> 
        <p className='parrafo_generador'>8. Genere la etiqueta en formato pdf e imprímala:</p>
        <button type="submit" className='btn_generador'>Generar etiqueta</button>
      </form> 
    </div>
  )
}

