import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join"; 


export class Login {
    static async list(email, pass) {
        const url = "https://serviciosecologicos.com/api/users/login";
        try{ 
            const response = await axios.post(url, {email, pass}, { headers: {"User-Agent": 'KCS-Client V1.0.0'}, 'Access-Control-Allow-Origin': '*'});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
             
        } catch(error){
            return false;
        }
        /* try{ 
            const response = await axios.post(url, {email, pass}, { headers: {"User-Agent": 'KCS-Client V1.0.0' , 'Access-Control-Allow-Origin': '*', 'origin':'x-requested-with', 'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin,privatekey', 'Content-Type': 'application/json'}, withCredentials: true});

            console.log(response)

            /*const response = await fetch(url, {method: "POST", body: JSON.stringify({email, password: pass}), headers: {'Content-Type': 'application/json'}, mode: "no-cors"})
            console.log(response)
            const data = await response.text()
            console.log(data)*/
             
         
    }
}