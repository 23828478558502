import './ElementoLista.css'; 

export default function ElementoLista({cod_log, residuo, detalles, image}) {

  return (
    <div className=''>
      <div className='row align-items-end'>
        <div className='col-3 p-2'>
        <img className="imagen_etiquetas" title='Etiqueta' alt="" src={image} />
        </div>
        <div className='col-6'>
          <p className='parrafo_c_ler'>Código LER:<span>{cod_log}</span></p>
          <p className='parrafo_detalles'>{detalles}</p>
          <p className='parrafo_residuo'>Nombre residuo:<span>{residuo}</span></p>
        </div>
        <div className='col-3'>
          <img className="flecha_etiquetas" title='Etiqueta' alt="" src="/img/flecha128.png" />
          <img className="cerrar_etiquetas" title='Etiqueta' alt="" src="/img/cerrar128.png" />
        </div>
      </div>
      <hr />
    </div>
  )
}

