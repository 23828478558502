import React, { useState } from 'react';
import './MisDatos.css';
import Menu from '../Menu/Menu';

export default function MisDatosForm() {

  const [empresa, setEmpresa] = useState('Ilunes SL');
  const [nima, setNima] = useState('3784623');
  const [email, setEmail] = useState('daniel@ilune.com');
  const [direccion, setDireccion] = useState('Plaza Sancho Abarca 2, BAJO. 31014 PAMPLONA');
  const [telefono, setTelefono] = useState('948 14 39 80');
  const [n_password, setN_Password] = useState('12345678');
  const [c_password, setC_Password] = useState('12345678');
  const [logo, setLogo] = useState('');

  function handleEmpresaChange(e) {
    const { name, value } = e.target
    setEmpresa(value)
  }

  function handleNimaChange(e) {
    const { name, value } = e.target
    setNima(value)
  }

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
  }

  function handleDireccionChange(e) {
    const { name, value } = e.target
    setDireccion(value)
  }

  function handleTelefonoChange(e) {
    const { name, value } = e.target
    setTelefono(value)
  }

  function handleN_PasswordChange(e) {
    const { name, value } = e.target
    setN_Password(value)
  }

  function handleC_PasswordChange(e) {
    const { name, value } = e.target
    setC_Password(value)
  }

  function cargarImagen(e) {
    const { name, value } = e.target
    setLogo(value)
    alert(email)
  }

  function handleButtonSubmit(e) {
    e.preventDefault()
    alert(email)
  }

  return (
    <div className='wrapper'> 
      <Menu index={1}/>
      <form className='form_mis_datos' onSubmit={handleButtonSubmit}>
        <h2>Mis Datos</h2>
        <label className='' for>
          EMPRESA:
          <input
            className='sin_borde_mis_datos'
            type="text"
            name="empresa" 
            value={empresa}
            onChange={handleEmpresaChange}
          />
        </label>
        <label className='' for>
          NIMA:
          <input
            className='sin_borde_mis_datos'
            type="text"
            name="empresa" 
            value={nima}
            onChange={handleNimaChange}
          />
        </label>
        <label className='separar_mis_datos' for> 
          EMAIL:
          <input
            className='sin_borde_mis_datos'
            type="text"
            name="email" 
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label className='separar_mis_datos' for>
          DIRECCIÓN:
          <input
            className='sin_borde_mis_datos'
            type="text"
            name="direccion" 
            value={direccion}
            onChange={handleDireccionChange}
          />
        </label>
        <label className='separar_mis_datos' for>
          TELÉFONO:
          <input
            className='sin_borde_mis_datos'
            type="tel"
            name="telefono" 
            value={telefono}
            onChange={handleTelefonoChange}
          />
        </label>
        <label className='separar_mis_datos margen_contra' for> 
          MODIFICAR CONTRASEÑA:
          <input
            className='sin_borde_mis_datos'
            type="password"
            name="n_password" 
            value={n_password}
            onChange={handleN_PasswordChange}
          />
        </label>
        <label className='separar_mis_datos' for> 
          CONFIRMAR CONTRASEÑA:
          <input
            className='sin_borde_mis_datos'
            type="password"
            name="c_password" 
            value={c_password}
            onChange={handleC_PasswordChange}
          />
        </label> 
        <div className="div_subir_imagen_mis_datos">
          <img className="imagen_cargada_mis_datos" title='Iniciar sesión' alt="" src="/img/login128.png"/> 
          <div className='cargar_imagen_mis_datos' onClick={cargarImagen} >
            <img className="subir_imagen_mis_datos" title='Iniciar sesión' alt="" src="/img/subir_imagen128.png"/>
            <p id='imagen_subida'>SUBIR IMAGEN</p>  
          </div> 
        </div>
        <button className='btn_form_mis_datos' type="submit">GUARDAR</button>  
      </form> 
    </div>
  ) 
}

