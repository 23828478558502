import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

export default function VistaForm({ index }) {

  const img_b = "/img/blanco_";
  const img_v = "/img/verde_";

  const class_b = "sub_div_menu_b";
  const class_v = "sub_div_menu_v";

  let src_1 = (index == 0 ? img_v : img_b) + "1.svg";
  let src_2 = (index == 1 ? img_v : img_b) + "2.svg";
  let src_3 = (index == 2 ? img_v : img_b) + "3.svg";

  let sub_div_menu_1 = (index == 0 ? class_b : class_v);
  let sub_div_menu_2 = (index == 1 ? class_b : class_v);
  let sub_div_menu_3 = (index == 2 ? class_b : class_v);


  return (
    <div className='div_principal_menu'>
      <Link to="/generador">
        <div className={sub_div_menu_1}>
          <img className='imagen_menu' title='Nueva etiqueta' alt="Nueva etiqueta" src={src_1} />
          <p className='p_menu'>Nueva etiqueta</p>
        </div>
      </Link>
      <Link to="/datos">
        <div className={sub_div_menu_2}>
          <img className='imagen_menu' title='Mis datos' alt="Mis datos" src={src_2} />
          <p className='p_menu'>Mis datos</p>
        </div>
      </Link>
      <Link to="/vista">
        <div className={sub_div_menu_3}>
          <img className='imagen_menu' title='Mis etiquetas' alt="Mis etiquetas" src={src_3} />
          <p className='p_menu'>Mis etiquetas</p>
        </div>
      </Link>
    </div>
  )
}

