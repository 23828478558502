import React, { useState } from 'react';
import './Registro.css';

export default function RegistroForm() {

  const [empresa, setEmpresa] = useState('Ilunes SL'); 
  const [email, setEmail] = useState('daniel@ilune.com');
  const [direccion, setDireccion] = useState('Plaza Sancho Abarca 2, BAJO. 31014 PAMPLONA');
  const [telefono, setTelefono] = useState('948 14 39 80');
  const [password, setPassword] = useState('1234');
  const [c_password, setC_Password] = useState('1234');

  function handleEmpresaChange(e) {
    const { name, value } = e.target
    setEmpresa(value)
  }

  function handleEmailChange(e) {
    const { name, value } = e.target
    setEmail(value)
  }

  function handleDireccionChange(e) {
    const { name, value } = e.target
    setDireccion(value)
  }

  function handleTelefonoChange(e) {
    const { name, value } = e.target
    setTelefono(value)
  }

  function handlePasswordChange(e) {
    const { name, value } = e.target
    setPassword(value)
  }

  function handleC_PasswordChange(e) {
    const { name, value } = e.target
    setC_Password(value)
  }

  function handleButtonSubmit(e) {
    e.preventDefault()
    alert(email)
  }

  return (
    <div> 
      <form className="form_registro" onSubmit={handleButtonSubmit}>
        <label className='label_registro' for>
          EMPRESA:
          <input 
            className='sin_borde_registro'
            type="text"
            name="empresa" 
            value={empresa}
            onChange={handleEmpresaChange}
          />
        </label>
        <label className='label_registro' for> 
          EMAIL:
          <input
            className='sin_borde_registro'
            type="text"
            name="email" 
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label className='label_registro' for>
          DIRECCIÓN:
          <input
            className='sin_borde_registro'
            type="text"
            name="direccion" 
            value={direccion}
            onChange={handleDireccionChange}
          />
        </label>
        <label className='label_registro' for>
          TELÉFONO:
          <input
            className='sin_borde_registro'
            type="tel"
            name="telefono" 
            value={telefono}
            onChange={handleTelefonoChange}
          />
        </label>
        <label className='label_registro margen_registro' for> 
          CONTRASEÑA:
          <input
            className='sin_borde_registro'
            type="password"
            name="password" 
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <label className='label_registro' for> 
          CONFIRMAR CONTRASEÑA:
          <input
            className='sin_borde_registro'
            type="password"
            name="c_password" 
            value={c_password}
            onChange={handleC_PasswordChange}
          />
        </label> 
        <label className='checkbox_form_registro' for> 
          <input type="checkbox" id="acepto" name="acepto" value="acepto"/> 
          <label for="acepto">Acepto la política de privacidad</label><br></br>
        </label>
        <button className='btn_form_registro' type="submit">REGISTRARME</button>  
      </form> 
    </div>
  )
}

