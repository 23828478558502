import './App.css'; 
import Menu from '../Menu/Menu'
import Login from '../Login/Login';
import Registro from '../Registro/Registro';
import MisDatos from '../MisDatos/MisDatos'
import Generador from '../Generador/Generador'
import Vista from '../Vista/Vista'
import { Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="wrapper-app">
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/registro' element={<Registro />} />
        <Route path='/generador' element={<Generador />} />
        <Route path='/datos' element={<MisDatos />} />
        <Route path='/vista' element={<Vista />} />
      </Routes>
      
    </div>
  );
}

export default App;
