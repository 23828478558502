import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; 
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Login } from "./requests/login";

const root = ReactDOM.createRoot(document.getElementById('root'));
// iniciamos el router
root.render(
  <BrowserRouter> 
    <App />
  </BrowserRouter>
);

Login.list("mcarrion@kcramsolutions.com", "Estafeta,13").then (
	(data) => {
		console.log(data)
	}
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
